import React from "react";
import "./Hero.scss";
import HeroImg from "../../assets/img/hero-img.png";
import MovingComponent from "react-moving-text";

function Hero() {
  return (
    <div className="hero-container">
      <div className="hero-left">
        <img src={HeroImg} alt="" />
      </div>
      <div className="hero-right">
        <div style={{ display: "flex", alignItems: "center" }}>
          <span className="percent">30%</span>{" "}
          <span style={{ marginLeft: 15, marginBottom: -15 }}>
            de réduction
          </span>
        </div>
        <div style={{ marginTop: 10 }}>
          Sur la conception et la refonte de votre site web avec{" "}
          <MovingComponent
            type="shadow"
            duration="1000ms"
            delay={`0s`}
            direction="alternate"
            timing="ease"
            iteration="infinite"
            fillMode="none"
            style={{ display: "inline", color: "#1eac0f", fontSize: 30 }}
          >
            2 Mois
          </MovingComponent> {" "}
          de maintenance gratuite au lieu d'1
        </div>
      </div>
    </div>
  );
}

export default Hero;
