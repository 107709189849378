import {
  Header,
  Hero,
  Form,
  Services,
  Stats,
  Testimonial,
  Iframe,
  Footer,
} from "./components";
import "./App.css";

function App() {
  return (
    <div className="App">
      <Header />
      <Hero />
      <Form />
      <Services />
      <Stats />
      <Testimonial />
      <Iframe />
      <Footer />
    </div>
  );
}

export default App;
