import React from "react";
import "./Header.css";

import Logo from "../../assets/logo.png";

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  return (
    <div className="header-container">
      <img style={{height: 50}} src={Logo} alt="" />
    </div>
  );
};
