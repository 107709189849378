import React, { useState } from "react";
import ClockLoader from "react-spinners/ClockLoader";
import emailjs from "@emailjs/browser";
import tw from "twin.macro";
import styled from "styled-components";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "./Form.scss";

const override = {
  display: "block",
  margin: "0 auto",
  borderColor: "red",
};



function Form() {
  const [form, setForm] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };
  const handlePhoneChange = (value, country, e, formattedValue) => {
    setForm({ ...form, contact: formattedValue});
  };

  const onSubmit = () => {
    console.log("form", form);
    setIsLoading(true);
    const _err = {};
    if (!form.email || form.email == "") {
      _err.email = "Veuillez renseigner ce champs";
    }
    if (!form.firstname || form.firstname == "") {
      _err.firstname = "Veuillez renseigner ce champs";
    }
    if (!form.lastname || form.lastname == "") {
      _err.lastname = "Veuillez renseigner ce champs";
    }
    if (!form.contact || form.contact == "") {
      _err.contact = "Veuillez renseigner ce champs";
    }

    if (Object.keys(_err).length > 0) {
      setErrors(_err);
      setIsLoading(false);
      return;
    }

    emailjs
      .send(
        "service_wut1a4s",
        "template_vnhxxik",
        {
          ...form,
          message: "",
          //reply_to: "nericeeno@gmail.com",
        },
        "Qw1nRbilaGsAhX3ao"
      )
      .then(
        (response) => {
          console.log("SUCCESS!", response.status, response.text);
          setIsSubmitted(true);
          setForm({});
          setIsLoading(false);
        },
        (err) => {
          console.log("FAILED...", err);
          setIsLoading(false);
        }
      );
  };
  return (
    <div className="form-container">
      {!isSubmitted && (
        <div style={{width:"100%",display:"flex",justifyContent:"center"}}>
        <p style={{background:"#F0F8FF",width:"fit-content",padding: 10}}>Remplissez le formulaire ci-dessous pour profiter de l'offre</p>
        </div>
      )}
   
      {!isSubmitted && (
        <div className="form">
          <div className="form-left">
            <input
              className="fm-input"
              onChange={handleChange}
              name="lastname"
              type="text"
              placeholder="Nom * :"
            />
            {errors && errors.lastname && (
              <p style={{ color: "red", fontSize: 10, textAlign: "left" }}>
                {errors.lastname}
              </p>
            )}
            {/**
           <select onChange={handleChange}   className="fm-input" name="country" id="country">
              <option value="">Pays:</option>
              <option value="Benin">Bénin</option>
              <option value="Côte d'ivoire">Côte d'ivoire</option>
              <option value="France">France</option>
            </select>
            {errors && errors.country && (
              <p style={{ color: "red", fontSize: 10, textAlign: "left" }}>
                {errors.country}
              </p>
            )} */}
            <input
            className="fm-input"
            onChange={handleChange}
            name="firstname"
            type="text"
            placeholder="Prénom * :"
          />
          {errors && errors.firstname && (
            <p style={{ color: "red", fontSize: 10, textAlign: "left" }}>
              {errors.firstname}
            </p>
          )}
            
            <input
              onChange={handleChange}
              name="profession"
              className="fm-input"
              type="text"
              placeholder="Profession:"
            />
            {errors && errors.profession && (
              <p style={{ color: "red", fontSize: 10, textAlign: "left" }}>
                {errors.profession}
              </p>
            )}
            <select
              className="fm-input"
              onChange={handleChange}
              name="type"
              id="offre"
            >
              <option value="">Type d'offre:</option>
              <option value="Création de Site web">Création de Site web</option>
              <option value="Refonte de Site web">Refonte de Site web</option>
            </select>
          </div>
          {errors && errors.type && (
            <p style={{ color: "red", fontSize: 10, textAlign: "left" }}>
              {errors.type}
            </p>
          )}
          <div className="form-right">
          <input
          onChange={handleChange}
          className="fm-input"
          name="email"
          type="text"
          placeholder="Email * :"
        />
        {errors && errors.email && (
          <p style={{ color: "red", fontSize: 10, textAlign: "left" }}>
            {errors.email}
          </p>
        )}
            <PhoneInput
              country={"bj"}
              containerStyle={{ marginTop: 20, width: "80%" }}
              inputStyle={{ width: "100%", padding: 30, paddingLeft: 50 }}
              placeholder="Téléphone * :"
              onChange={handlePhoneChange}
              enableSearch
              // value={form?.phone || ""}
            />
            {errors && errors.contact && (
              <p style={{ color: "red", fontSize: 10, textAlign: "left" }}>
                {errors.contact}
              </p>
            )}
            <input
              onChange={handleChange}
              className="fm-input"
              name="company"
              type="text"
              placeholder="Société:"
            />
            {errors && errors.company && (
              <p style={{ color: "red", fontSize: 10, textAlign: "left" }}>
                {errors.company}
              </p>
            )}
            {/**
           <input
              onChange={handleChange}
              className="fm-input"
              name="department"
              type="text"
              placeholder="Département/Service"
            />
            {errors && errors.department && (
              <p style={{ color: "red", fontSize: 10, textAlign: "left" }}>
                {errors.department}
              </p>
            )} */}
            <input
              onChange={handleChange}
              className="fm-input"
              name="sector"
              type="text"
              placeholder="Secteur d'activité:"
            />
            {errors && errors.sector && (
              <p style={{ color: "red", fontSize: 10, textAlign: "left" }}>
                {errors.sector}
              </p>
            )}
          </div>
          <div className="form-submit">
            <button onClick={onSubmit} style={{ cursor: "pointer" }}>
              {isLoading ? (
                <ClockLoader
                  color="white"
                  loading={isLoading}
                  cssOverride={override}
                  size={30}
                />
              ) : (
                "JE SOUSCRIS"
              )}
            </button>
          </div>
        </div>
      )}
      {isSubmitted && (
        <p style={{ color: "#1eac0f" }}>
          Votre demande a bien été transmise, vérifiez votre boite de
          réception, <br />
          <br /> Merci !
        </p>
      )}
    </div>
  );
}

export default Form;
