import React from "react";
import "./Testimonial.scss";

import Logo1 from "../../assets/img/logo1.jpg";
import Logo2 from "../../assets/img/logo2.jpg";
import Logo3 from "../../assets/img/logo3.jpg";
import Logo4 from "../../assets/img/logo4.jpg";
import Logo5 from "../../assets/img/logo5.jpg";

const Testimonial = () => {
  return (
    <div className="testimonial-container">
      <h4 style={{ textDecoration: "underline" }}>Ils nous font confiance</h4>
      <div className="testimonials">
        <img src={Logo2} alt="" />
        <img src={Logo3} alt="" />
        <img src={Logo5} alt="" />
        <img src={Logo1} alt="" />
        <img src={Logo4} alt="" />
        
      </div>
    </div>
  );
};

export default Testimonial;
