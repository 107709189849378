import React from "react";

import "./Footer.scss";
import FbIcon from "../../assets/icons/facebook.png";
import Igcon from "../../assets/icons/instagram.png";
import LdIcon from "../../assets/icons/linkedin.png";
import YtIcon from "../../assets/icons/youtube.png";
import {FaFacebook,FaInstagramSquare,FaLinkedin,FaYoutubeSquare} from "react-icons/fa";


const Footer = ()=>{
    return(
        <div className="footer-container">
            Kutonou Agency
            <div className="icons-list">
               <a target="_blank" href="https://www.facebook.com/people/Kutonou-Agency/100039556102488" style={{marginLeft: 3,color:"white"}} rel="noreferrer"> <FaFacebook /></a>
               <a target="_blank" href="https://www.instagram.com/kutonouagency" style={{marginLeft: 3,color:"white"}} rel="noreferrer"> <FaInstagramSquare /></a>
               <a target="_blank" href="https://www.linkedin.com/company/kutonou-agency" style={{marginLeft: 3,color:"white"}} rel="noreferrer"> <FaLinkedin /></a>
               <a target="_blank" href="https://www.youtube.com/channel/UC_2CK-p1C55Oanq1G8pbTnQ" style={{marginLeft: 3,color:"white"}} rel="noreferrer"> <FaYoutubeSquare /></a>
            </div>
        </div>
    )
}

export default Footer;