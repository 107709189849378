import React from "react";
import "./Stats.scss";

const Stats = () => {
  return (
    <div className="stats-container">
      <h1>Plus de 117 projets terminés</h1>
      {/**<p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
        tempor incididunt ut labore et dolore magna aliqua.
      </p> */}
      <div className="cards-container">
        <div className="card">
          <h1>95+</h1>
          <span>Clients satisfaits</span>
        </div>
        <div className="card">
          <h1>117+</h1>
          <span>Projets terminés</span> 
        </div>
        <div className="card">
          <h1>5+</h1>
          <span>Projets en cours</span>
        </div>
      </div>
    </div>
  );
};

export default Stats;
