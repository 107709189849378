import React from "react";
import "./Services.scss";
import MarketingSvg from "../../assets/icons/marketing.svg";
import MotionSvg from "../../assets/icons/motion.svg";
import MobileSvg from "../../assets/icons/mobile.svg";
import SocialsSvg from "../../assets/icons/socials.svg";
import WebSvg from "../../assets/icons/web.svg";
import designSvg from "../../assets/icons/design.svg";

const Services = () => {
  return (
    <div className="services-container">
      <div className="entete">
        <h1>Nos services.</h1>
        <p>Découvrez nos autres offres</p>
      </div>
      <div className="services">
        <div className="card">
          <img src={MarketingSvg} alt="" />
          <div>
            <h3>
              <a href="https://kutonou.com/Social-Media" target="_blank" rel="noreferrer">
                Communication et Marketing{" "}
              </a>
            </h3>
            <p>
              Faire connaître votre marque ou votre entreprise en leur donnant
              plus de visibilité.
            </p>
          </div>
        </div>
        <div className="card">
          <img src={SocialsSvg} alt="" />
          <div>
            <h3>
              <a href="https://kutonou.com/Strategie-Marketing" target="_blank" rel="noreferrer">
                {" "}
                Social Media
              </a>
            </h3>
            <p>
              Booster votre activité et vos ventes en interagissant au quotidien
              avec votre communauté et vos clients.
            </p>
          </div>
        </div>
        <div className="card">
          <img src={designSvg} alt="" />
          <div>
            <h3>
              <a href="https://kutonou.com/Logo-Charte-graphique" target="_blank" rel="noreferrer">
                Graphisme & Design
              </a>
            </h3>
            <p>Personnalisez vos idées de création !</p>
          </div>
        </div>
        <div className="card">
          <img src={MotionSvg} alt="" />
          <div>
            <h3>
              <a href="https://kutonou.com/Motion-design" target="_blank" rel="noreferrer">Motion design</a>
            </h3>
            <p>
              Captivez votre audience, présentez vos produits et services et
              informez vos clients par l’animation graphique.
            </p>
          </div>
        </div>
        <div className="card">
          <img src={MobileSvg} alt="" />
          <div>
            <h3>
              <a href="https://kutonou.com/Application-mobile" target="_blank" rel="noreferrer">
                Application mobile
              </a>
            </h3>
            <p>
              Démarquez vous en créant une application intuitive et pratique
              pour l’utilisation de vos services en ligne.
            </p>
          </div>
        </div>
        <div className="card">
          <img src={WebSvg} alt="" />

          <div>
            <h3>
              <a href="https://kutonou.com/Site-Web" target="_blank" rel="noreferrer">Site Web</a>
            </h3>
            <p>
              Créez un site internet personnalisé et offrez la plus belle
              vitrine pour vos produits et services.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
